<template>
  <div class="px-4 container mx-auto my-12 md:my-24 max-w-xl">
    <div
      v-if="success"
      class="text-center">
      <headline class="mb-8">
        Takk!
      </headline>
      <span class="loading-ellipsis">Betaling reservert, du blir snart sendt videre til utsjekk.</span>
    </div>
    <div v-else-if="notFound">
      <headline class="mb-8 text-center">
        Ikke funnet
      </headline>

      <p class="text-center mb-8">
        Systemet fant ikke forespørselen. Kontakt oss hvis du mener dette er feil.
      </p>

      <p class="text-center mb-8">
        <a href="/">
          <default-button
            primary
            large
          >
            Til forsiden
          </default-button>
        </a>
      </p>
    </div>

    <div v-else-if="canceled">
      <headline class="mb-8 text-center">
        Betalingen ble avbrutt
      </headline>

      <p class="text-center mb-8">
        <a href="/">
          <default-button
            primary
            large
          >
            Til forsiden
          </default-button>
        </a>
      </p>
    </div>

    <div v-else-if="error">
      <headline class="mb-8 text-center">
        En feil har oppstått
      </headline>

      <p class="text-center mb-8">
        Vennligst prøv igjen, og kontakt oss hvis feilen vedvarer.
      </p>

      <p class="text-center">
        <a href="/">
          <default-button
            primary
            large
          >
            Til forsiden
          </default-button>
        </a>
      </p>
    </div>

    <div v-else>
      <headline class="mb-8 text-center">
        <span class="loading-ellipsis">Behandler</span>
      </headline>
    </div>
  </div>
</template>

<script>
import axios from "axios"

export default {
  props: {
    orderId: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      tries: 0,
      error: false,
      canceled: false,
      notFound: false,
      success: false,
      timeoutId: null
    }
  },

  methods: {
    loadData () {
      // Check if the order has been saved to Shopify
      axios.post("/api/vipps/status/" + this.orderId).then(({ data }) => {
        const shopifyReceiptUrl = data.shopifyReceiptUrl

        // Redirect to Shopify receipt
        if (shopifyReceiptUrl) {
          this.success = true
          window.setTimeout(() => {
            window.location.replace(shopifyReceiptUrl)
          }, 5000)
        }
      }).catch(error => {
        console.error(error) // eslint-disable-line no-console

        if (error.response.status === 404) {
          this.notFound = true
        } else if (error.response.status === 410) {
          this.canceled = true
        } else if (this.tries < 30) {
          this.timeoutId = window.setTimeout(this.loadData, 5000)
          this.tries++
        } else {
          this.error = true
        }
      })
    }
  },

  mounted () {
    this.loadData()
  },

  beforeDestroy() {
    clearTimeout(this.timeoutId)
  }
}
</script>

<style type="text/css" scoped>
.loading-ellipsis:after {
  position: absolute;
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
</style>
