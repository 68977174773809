<template>
  <div>
    <vipps-status-check :order-id="$route.params.orderId" />
  </div>
</template>

<script>
import VippsStatusCheck from "../components/VippsStatusCheck.vue"

export default {
  components: {
    VippsStatusCheck
  },
}
</script>
